.recipe-card, .empty-recipe-card {
    display: flex;
    flex-direction: column;
    /* flex: 0 1 calc(25% - 16px); */
    border-radius: 16px;
    background: var(--background-layer-1, #1E1E1E);
    width: 300px;
}

.empty-recipe-card {
    justify-content: center;
    align-items: center;
    padding: 16px;
    gap: 16px;
    color: var(--color-white, #FFFFFF);
}

.recipe-card:hover {
    outline-style: solid;
    outline-color: var(--accent-color, #BB86FC);
}

.recipe-card-top {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 16px 24px;
    border-radius: 16px 16px 0px 0px;
    background: var(--background-layer-2, #2E2E2E);
    min-height: 100px;
}

.recipe-card-top:hover {
    cursor: pointer;
}

.recipe-card-bottom {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 16px 24px;
    border-radius: 0px 0px 16px 16px;
    background: var(--background-layer-1, #1E1E1E);
}

.card-name {
    font-size: 24px;
    font-weight: 600;
    font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
    color: var(--color-white, #FFFFFF);
    margin-bottom: 16px;
    text-align: center;
}

.card-icons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    width: 100%;
    align-items: flex-end;
    /* gap: 8px; */
}

.card-icon {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 8px;
    border-radius: 8px;
    background: var(--background-layer-2, #2E2E2E);
    margin: 0px 8px;
}

.card-icon:hover {
    cursor: pointer;
}