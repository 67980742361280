h1, p {
    color: white;
}

.error-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 16px;
    background: var(--background-layer-1, #1E1E1E);
    border-radius: 16px;
    margin: 16px;
}