.recipe-view {
    display: flex;
    flex-direction: column;
    flex: 1 1 0;
    border-radius: 16px;
    background: var(--background-layer-1, #1E1E1E);
    padding: 16px 24px;
    min-width: 20%;
}

.card-body textarea, .card-body input {
    width: 100%;
    height: 100%;
}

.card-header input {
    font-size: 30px;
}

.card-body textarea {
    min-height: 50px;
    max-height: 200px;
}

.ingredients-list li {
    padding: 4px;
}

.instruction-step textarea {
    height: 100%;
}

.instruction-step .regular-font {
    padding: 8px;
    max-width: 90%;
}

.divider {
    border-bottom: 1px solid grey;
    height: 1px;
}

.card-header {
    display: flex;
    padding: 16px 0px 16px 0px;
    justify-content: space-between;
}

.header-left {
    display: flex;
    padding: 8px;
    width: 100%;
}

.header-right {
    display: flex;
    top: 5px;
    right: 5px;
    gap: 10px;
}

.icon-bg {
    display: flex;
    padding: 10px;
    border-radius: 10px;
    margin-top: 0px;
    align-items: center;
    justify-content: center;
    width: 20px;
    height: 20px;
}

.icon-bg:hover {
    background: var(--accent-color, #BB86FC);
    cursor: pointer;
}

.card-body {
    display: flex;
    padding-top: 16px;
    flex-direction: row;
    gap: 32px;
}

.instruction-step {
    display: flex;
    flex-direction: column;
}

.ingredients-list {
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.ingredients {
    display: flex;
    flex-direction: column;
    flex-basis: 100%;
}

.instructions {
    display: flex;
    flex-direction: column;
    flex-basis: 100%
}

.purple-color {
    color: var(--background-layer-1, #1E1E1E);
}

.chip {
    display: flex;
    background: var(--accent-color, #BB86FC);
    border-radius: 8px;
    padding: 8px;
    margin-bottom: 16px;
    width: max-content;
    pointer-events: none;
}

.recipe-view .submit-button {
    background: var(--accent-color, #BB86FC);
    border: none;
    border-radius: 8px;
    padding: 8px;
    color: var(--color-white, #FFFFFF);
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    height: 40px;
    min-width: 80px;
}

.submit-button:hover {
    background: var(--accent-color-hover, #a472e2);
}

.add-field {
    background: var(--background-layer-2, #2E2E2E);
    border: none;
    border-radius: 8px;
    padding: 8px;
    color: var(--color-white, #FFFFFF);
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
}

.ingredients .add-field,
.instructions .add-field {
    align-self: center;
}

.add-field:hover {
    background: var(--background-layer-3, #3f3f3f);
}

.delete-field {
    background: var(--background-layer-2, #2E2E2E);
    border: none;
    border-radius: 8px;
    padding: 8px;
    color: var(--color-white, #FFFFFF);
    font-family: 'Roboto', sans-serif;
    cursor: pointer;
}

.delete-field:hover {
    background: var(--background-layer-3, #3f3f3f);
}

.map-item {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 8px;
    margin-bottom: 12px;
}

.ingredients-list .map-item {
    height:20px;
}

.map-item input:disabled, .map-item textarea:disabled, .card-header input:disabled {
    background: var(--background-layer-1, #1E1E1E);
}

.map-item input:disabled + .delete-field, .map-item textarea:disabled + .delete-field {
    display: none;
}


ul {
    margin-block-end: 0;
    padding-inline-start: 20px;
}

@media (max-width: 767px) {
    .card-header {
        flex-direction: column;
        gap: 16px;
    }
    .card-body {
        flex-direction: column;
    }
}
