.landing-page {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 48px 100px;
    gap: 32px
}

.landing-container-small {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    /* padding: 16px; */
    /* border-radius: 16px; */
    /* margin: 24px; */
    gap: 32px;
    width: 100%;
    max-width:1150px;
}

.landing-card {
    display: flex;
    flex-direction: row;
    /* align-items: flex-start; */
    /* flex: 1 1 0; */
    border-radius: 16px;
    background: var(--background-layer-1, #1E1E1E);
    /* padding: 24px 24px; */
    width: 100%;
    justify-content: space-between;
    max-width: 1150px;
}

.landing-card-small {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex: 1 1 0;
    border-radius: 16px;
    background: var(--background-layer-1, #1E1E1E);
    padding: 24px 24px;
    /* width: 100%; */
    height: 120px;
}

.lottie-animation {
    display: flex;
    align-items: center;
    width:100%;
}

.lottie-animation div {
    display:flex;
    justify-content: center;
}

.lottie-animation canvas {
    max-height: 300px;
    max-width:300px;
}

.header-stack {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    gap: 4px;
    margin: 0;
    max-width: 500px;
    padding: 24px;
}

@media screen and (max-width: 1000px) {
    .landing-page {
        margin: 24px 16px;
        
    }

    .landing-container-small {
        flex-direction: column;
        max-width: 550px;
    }

    .landing-card {
        align-items: center;
        text-align: center;
        max-width: 550px;
        justify-content: center;
    }

    .landing-card-small {
        align-items: center;
        text-align: center;
    }

    .lottie-animation {
        display: none;
    }
    
    .header-stack {
        align-items: center;
    }
}

.landing-buttons {
    display: flex;
    flex-direction: row;
    gap: 16px;
    margin-top: 16px;

}

.header-trick {
    display: flex;
    flex-direction: row;
    gap: 10px;
}

.header-purple {
    color: var(--accent-color, #BB86FC) !important;
}

.landing-container svg {
    display: none;
}