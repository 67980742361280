h2 {
    margin: 0;
}

a {
    text-decoration: none;
}

.top-bar {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    background: var(--background-layer-1, #1E1E1E);;
    padding: 8px;
    height: 64px;
}

.top-bar-left {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    
}

.top-bar-right {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 8px;
    gap: 8px;
}

.top-bar-button {
    background: var(--accent-color, #BB86FC);
    border: none;
    border-radius: 8px;
    padding: 8px;
    color: var(--color-white, #FFFFFF);;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    min-width: 100px;
}

.top-bar-icon {
    padding: 8px;
    border-radius: 8px;
    background: var(--accent-color, #BB86FC);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.top-bar-icon:hover:not(.logout-button),
.top-bar-button:hover:not(.logout-button) {
    background: var(--accent-color-hover, #a472e2);
}

@media screen and (min-width: 1024px) {
    .top-bar .top-bar-button {
        display: block
    }
    
    .top-bar .top-bar-icon {
        display: none;
    }
    
}

@media screen and (max-width: 1024px) {
    .top-bar .top-bar-button {
        display: none;
    }
    
    .top-bar .top-bar-icon {
        display: block;
    }
}

.logout-button {
    background: var(--background-layer-3, #3f3f3f);
}