.post-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
    padding: 16px;
    border-radius: 8px;
}

.form-label {
    display: flex;
    gap: 8px;
    color: white;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 16px;
    justify-content: center;
    align-items: center;
}

.modal-form {
    display: flex;
    background: var(--background-layer-1, #1E1E1E);
    border-radius: 8px;
    padding: 16px;
    gap: 16px;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    min-width: 275px;
}

.form-buttons {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 16px;
}

.submit-button {
    color: var(--background-layer-1, #1E1E1E);
}

.post-form .submit-button {
    background: var(--accent-color, #BB86FC);
    border: none;
    border-radius: 8px;
    padding: 8px;
    color: var(--color-white, #FFFFFF);
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    height: 40px;
}

.form-label .instruction-step, .form {
    margin-top: 4px;
    margin-bottom: 4px;
}

.form-label .ingredients-list .map-item:last-of-type {
    margin-bottom: 8px;
}

.form-label .ingredients {
    justify-content: center;
    align-items: center;
}

.form-label .instructions {
    justify-content: center;
    align-items: center;
}