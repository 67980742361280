.modal {
    display: block; /* Hidden by default */
    position: fixed; /* Stay in place */
    z-index: 1; /* Sit on top */
    left: 0;
    top: 0;
    width: 100%; /* Full width */
    height: 100%; /* Full height */
    overflow: hidden; /* Enable scroll if needed */
    background-color: rgb(0,0,0); /* Fallback color */
    background-color: rgba(0,0,0,0.4); /* Black w/ opacity */
}

.modal-content {
    display: flex;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: var(--background-layer-1, #1E1E1E);
    color: white;
    border-radius: 10px;
    padding: 20px;
    gap: 15px;
    width: 80%;
    height: 15%;
    max-width: 500px;
    /* box-shadow: 0 0 10px rgba(0,0,0,0.25); */
}

.modal .modal-form {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 80%;
}

.form-label {
    display: flex;
    flex-direction: column;
}

.modal .submit-button {
    background: var(--accent-color, #BB86FC);
    border: none;
    border-radius: 8px;
    padding: 8px;
    color: var(--color-white, #FFFFFF);
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 16px;
    cursor: pointer;
    height: 40px;
}

textarea {
    resize: none;
}

.modal-container {
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    gap: 30px;
    width: 100%;
}

.modal-buttons {
    display: flex;
    flex-direction: row;
    gap: 20px;
}

.modal-divider {
    border-top: 2px solid white;
    box-shadow: none;
    width: 80%;
}