body {
  margin: 0;
  font-family: 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: auto;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --accent-color: #BB86FC;
  --accent-color-hover: #a472e2;
  --background-layer-base: #121212;
  --background-layer-1: #1E1E1E;
  --background-layer-2: #2E2E2E;
  --background-layer-3: #3f3f3f;
  --color-white: #FFFFFF;
  --text-color-gray: #a9a9a9
}