html {
  background: var(--background-layer-base, #121212);
}


body {
  margin: 0;
  padding: 0;
  overflow: auto;
}

input, textarea {
  background: var(--background-layer-2, #2E2E2E);;
  border: none;
  border-radius: 8px;
  padding: 8px;
  color: var(--color-white, #FFFFFF);
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
  /* width: 50%; */
  resize: none;
  text-overflow: ellipsis;
}

.header-font {
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-size: 30px;
  color: var(--color-white, #FFFFFF);
}

.regular-font {
  font-family: 'Roboto', sans-serif;
  font-weight: 500;
  font-size: 16px;
  color: var(--color-white, #FFFFFF);
}

.header-secondary-font {
  font-family: 'Roboto', sans-serif;
  font-weight: 600;
  font-size: 16px;
  color: var(--background-layer-1, #1E1E1E);
}

.App {
  display: flex;
  justify-content: center;
}

.recipe-container {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  flex-basis: 0;
  gap: 16px;
  padding: 16px;
  flex-wrap: wrap;
  overflow: auto;
  justify-content: center;
}

::-webkit-scrollbar {
  width: 20px;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: var(--background-layer-3, #3f3f3f);
  border-radius: 20px;
  border: 6px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: var(--color-white, #FFFFFF);
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');